import React, { useContext, useEffect } from 'react'
import { graphql } from 'gatsby'
import { globals, s, colors, alpha, sHtml } from '../clever-ui-kit/style'
import { T } from '../clever-ui-kit/typo'
import { CareerMinis } from '../clever-ui-kit/minis'
import { ButtonMain } from '../clever-ui-kit/buttons'
// import '../clever-ui-kit/style/unused/styleOLD.css'

// CONTEXT
import { StateContext, DispatchContext, initialState } from '../context'

// COMPONENTS CONST
import SEO from '../components/seo'
import { IntroBlog } from '../clever-ui-kit/intros'

export default function CareerTemplate({ data }) {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)
  // const language = state?.language ? state.language : initialState.language
  const theme = state?.theme ? state.theme : initialState.theme

  const career = data.thisCareer?.frontmatter
  const careerHtml = data.thisCareer?.html

  /*eslint-disable */
  useEffect(() => {
    dispatch({ type: 'SET_MENU', payload: false })
  }, [])

  useEffect(() => {
    if (career.language)
      dispatch({ type: 'SET_LANGUAGE', payload: career.language })
  }, [])
  /*eslint-enable */

  return (
    <>
      <SEO
        title={career.seo_title || career.title}
        description={career.seo_desc || career.description}
        image={career.image?.publicURL}
      />
      <IntroBlog
        title={career.title}
        subtitle={career.description}
        image={career.image}
        date={career.date}
        theme={theme}
        language={career.language}
      />
      <div css={sTitle}>
        <T
          d={64}
          t={48}
          m={32}
          mb={0.5}
          bold
          variant="h1"
          extraCss={sTitle.headline}>
          {career.title}
        </T>
        <T d={20} t={18} m={14} o={1} normal>
          {career.description}
        </T>
      </div>
      <div
        css={[sHtml, sCareer, sTheme(theme)]}
        dangerouslySetInnerHTML={{ __html: careerHtml }}
      />
      <ButtonMain
        center
        theme={theme}
        link={`mailto:biuro@reklamadlabiznesu.pl?subject=Rekrutacja - ${career.title} | Reklama Dla Biznesu`}
        extraCss={{ padding: '0.875rem 6rem', margin: '0 2rem' }}>
        APLIKUJ
      </ButtonMain>
      <div css={{ marginBottom: '4rem' }}>
        <CareerMinis
          data={{
            language: career.language,
            slug_section: 'pozostale-oferty-pracy',
            title: 'RDB Kariera',
          }}
          removeMarginTop
          theme={theme}
        />
      </div>
    </>
  )
}

const sTheme = (theme) => ({
  backgroundColor: colors[theme].container.concat(alpha[80]),
  [s.md]: {
    p: {
      color: colors[theme].text.concat(alpha[80]),
    },
  },
})

const sCareer = {
  [s.sm_down]: {
    boxShadow: globals.shadows.sections.mobile,
    borderRadius: globals.roundness.sections.mobile,
    marginBottom: globals.spacing.inside.mobile,
  },
  [s.md]: {
    boxShadow: globals.shadows.sections.desktop,
    borderRadius: globals.roundness.sections.desktop,
    marginBottom: globals.spacing.inside.desktop,
  },
}

const sTitle = {
  margin: '0 auto',
  width: '100%',
  maxWidth: globals.maxWidth,
  [s.lg_1280]: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
  marginTop: '6rem',
  marginBottom: '6rem',
  textAlign: 'center',
  [s.sm_down]: {
    padding: '0 2rem',
    marginBottom: '4rem',
  },
  headline: {
    letterSpacing: '-0.05em',
  },
}

export const query = graphql`
  query($title: String!) {
    thisCareer: markdownRemark(
      fileAbsolutePath: { regex: "/careers/" }
      frontmatter: { title: { eq: $title } }
    ) {
      html
      frontmatter {
        title
        name
        url
        language
        author
        description
        date
        seo_title
        seo_desc
        image {
          name
          publicURL
          childImageSharp {
            fluid(
              maxWidth: 1920
              quality: 100
              toFormat: JPG
              jpegProgressive: true
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
